export default [
  'default',
  'animal',
  'bicycle',
  'bikecomputer',
  'boat',
  'bus',
  'car',
  'cargobicycle',
  'crane',
  'dashcam',
  'eletricbike',
  'energymeter',
  'helicopter',
  'luggage',
  'laptop',
  'lock',
  'motorcycle',
  'offroad',
  'person',
  'pickup',
  'plane',
  'tablet',
  'tractor',
  'train',
  'tram',
  'trolleybus',
  'truck',
  'ship',
  'scooter',
  'sensor',
  'smartphone',
  'smartwatch',
  'smarthome',
  'van',
];
